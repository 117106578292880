/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { CustomInfoBlock, CustomTooltip, RenderSvg } from 'components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './tableCellAppraisalGoal.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  appraisal?: null | {
    justification: string;
    rating: string;
  };
  title?: string;
  quickView?: boolean;
}

const TableCellAppraisalGoal = (props: Props): ReactElement => {
  const { id, appraisal, title, quickView } = props;

  const { t } = useTranslation('enum');

  return (
    <>
      {appraisal !== undefined && appraisal !== null ? (
        <div
          id={`tableCellAppraisalGoal-${id}`}
          className="tableCellAppraisalGoal"
        >
          <span
            id={`tableCellAppraisalGoal-${id}-rating`}
            className="tableCellAppraisalGoalRating"
          >
            {t(`appraisalGoalDetail.${appraisal.rating}`)}
          </span>
          {appraisal.justification !== '' &&
          appraisal.justification !== null ? (
            <>
              {quickView === undefined ? (
                <CustomInfoBlock
                  customText={appraisal.justification}
                  customTitle={title || 'Komentarz do samooceny'}
                  customSvg="comment"
                  widthBox="medium"
                  maxLevel="lvl3"
                >
                  <div
                    id={`tableCellAppraisalGoal-${id}-icon`}
                    className="circleBox"
                  >
                    <RenderSvg nameSvg="iconInfo" />
                  </div>
                </CustomInfoBlock>
              ) : (
                <CustomTooltip
                  customTitle={title || 'DODATKOWE INFORMACJE'}
                  customHtml={appraisal.justification}
                />
              )}
            </>
          ) : (
            '---'
          )}
        </div>
      ) : (
        '---'
      )}
    </>
  );
};

export default TableCellAppraisalGoal;
