import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
} from 'components';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal } from 'api/types';
import EmployeeAppraisalGoalAdd from './EmployeeAppraisalGoalAdd';
import EmployeeAppraisalGoalEdit from './EmployeeAppraisalGoalEdit';
import EmployeeAppraisalGoalList from './EmployeeAppraisalGoalList';
import EmployeeAppraisalGoalImport from './EmployeeAppraisalGoalmport';

interface Props {
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
  updateGoalUser?: (data: EmployeeAppraisalGoal) => void;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const { idEmployee } = useParams<{ idEmployee: string; idRecord: string }>();

  const { employeeAppraisalStatus, updateGoalUser } = props;

  const [addFormShow, setAddFormShow] = useState<boolean>(false);
  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [importFormShow, setImportFormShow] = useState<boolean>(false);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForEmployee({
    idEmployee: Number(idEmployee),
  });

  useEffect(() => {
    setLoaderTable(true);

    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  const DeleteRecord = EmployeeAppraisalRepository.useDeleteEmployeeGoal(
    Number(idEmployee),
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const handleDelete = (idClick: number) => {
    DeleteRecord(idClick);
  };

  const handleApproveClick = EmployeeAppraisalRepository.usePutEmployeeAppraisalAccept(
    Number(idEmployee),
    (data) => {
      if (updateGoalUser) {
        updateGoalUser(data);
      }
    }
  );

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="target60" />
                </div>
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfo">
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoLabel">
                    Cele roczne
                  </div>
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące celów pracownika
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="EmployeeAppraisalGoalBoxSecondRow">
            <>
              <div id="EmployeeAppraisalGoalBoxSecondRowFirstColumn">
                {employeeAppraisalStatus?.interviewType !== 'LAST_ANNUAL' &&
                employeeAppraisalStatus?.interviewType !== 'NONE' ? (
                  <>
                    <Button
                      id="EmployeeAppraisalGoalAddArea"
                      className="green "
                      onClick={() => {
                        setAddFormShow(true);
                      }}
                    >
                      Dodaj cel roczny
                    </Button>
                    {employeeAppraisalStatus?.settingId !== 7 ? (
                      <Button
                        id="EmployeeAppraisalGoalImportArea"
                        className="green"
                        onClick={() => {
                          setImportFormShow(true);
                        }}
                      >
                        Importuj cel roczny
                      </Button>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
              <div id="EmployeeAppraisalGoalBoxSecondRowSecondColumn">
                <div id="countGoal">
                  <div id="countGoalLabel">Dodano celów:</div>
                  <div id="countGoalData">
                    {dataGoal?.content.length}
                    {employeeAppraisalStatus &&
                    employeeAppraisalStatus?.settingId < 7 ? (
                      <>
                        <span>/</span> 5
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content &&
              dataGoal?.content.length > 0 &&
              employeeAppraisalStatus ? (
                <>
                  <EmployeeAppraisalGoalList
                    dataGoal={dataGoal.content}
                    setEditFormShow={setEditFormShow}
                    setModalId={setModalId}
                    deleteAction={
                      employeeAppraisalStatus !== undefined &&
                      employeeAppraisalStatus?.interviewType !==
                        'LAST_ANNUAL' &&
                      employeeAppraisalStatus?.interviewType !== 'NONE'
                        ? handleDelete
                        : undefined
                    }
                  />
                </>
              ) : (
                <div id="noGoalInAppraisal">Brak przypisanych celów</div>
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {employeeAppraisalStatus?.interviewType !== 'LAST_ANNUAL' &&
      employeeAppraisalStatus?.interviewType !== 'NONE' ? (
        <ContentBox id="approveIDP">
          <>
            <Button className="green" onClick={() => setShowModalYesNo(true)}>
              {employeeAppraisalStatus?.interviewType === 'FIRST_ANNUAL'
                ? 'ZATWIERDŹ CELE ROCZNE'
                : 'ZATWIERDŹ ROZMOWĘ PÓŁROCZNĄ'}
            </Button>
            {showModalYesNo ? (
              <ModalYesNo
                changeModalShow={setShowModalYesNo}
                yesAction={() => handleApproveClick()}
                noAction={() => setShowModalYesNo(false)}
                title={
                  employeeAppraisalStatus?.interviewType === 'FIRST_ANNUAL'
                    ? 'ZATWIERDZENIE CELÓW ROCZNYCH'
                    : 'ZATWIERDZENIE ROZMOWY PÓŁROCZNEJ'
                }
                additionalText={
                  employeeAppraisalStatus?.interviewType === 'FIRST_ANNUAL'
                    ? 'Czy chcesz zatwierdzić cele roczne? Po ich zatwierdzeniu nie bedzie można wprowadzać żadnych zmian do następnej rozmowy. Czy chcesz zatwierdzić je teraz?'
                    : 'Czy chcesz zatwierdzić rozmowę półroczną? Po jej zatwierdzeniu nie będzie można wprowadzać żadnych zmian w celach, nawet w ostatniej rozmowie, gdzie można tylko określić stopień realizacji i je ocenić. Czy chcesz teraz zatwierdzić rozmowę półroczną ?'
                }
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {addFormShow ? (
        <EmployeeAppraisalGoalAdd
          changeModalAdd={setAddFormShow}
          changeUpdateData={setUpdateDataTable}
          changeModalEdit={setEditFormShow}
          changeModalId={setModalId}
          idEmployee={Number(idEmployee)}
          employeeAppraisalStatus={employeeAppraisalStatus}
        />
      ) : (
        ''
      )}
      {editFormShow ? (
        <EmployeeAppraisalGoalEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
          modalId={modalId}
          employeeAppraisalStatus={employeeAppraisalStatus}
        />
      ) : (
        ''
      )}
      {importFormShow ? (
        <EmployeeAppraisalGoalImport
          changeModalImport={setImportFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
