import React, { ReactElement, useEffect, useState } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
} from 'components';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal, EmployeeAppraisalModel } from 'api/types';
import EmployeeAppraisalGoalAdd from './EmployeeAppraisalGoalAdd';
import EmployeeAppraisalGoalEdit from './EmployeeAppraisalGoalEdit';
import EmployeeAppraisalGoalList from './EmployeeAppraisalGoalList';
import EmployeeAppraisalGoalImport from './EmployeeAppraisalGoalmport';

interface Props {
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
  updateGoalUser?: (data: EmployeeAppraisalGoal) => void;
  idEmployee: number;
  idEvaluation: number;
  settings?: EmployeeAppraisalModel | null;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const {
    idEmployee,
    idEvaluation,
    employeeAppraisalStatus,
    updateGoalUser,
    settings,
  } = props;

  const [addFormShow, setAddFormShow] = useState<boolean>(false);
  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [importFormShow, setImportFormShow] = useState<boolean>(false);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);

  const [showModalUnapproval, setShowModalUnapproval] = useState<boolean>(
    false
  );

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForEmployeeAdmin({
    idEmployee,
    idEvaluation,
  });

  useEffect(() => {
    setLoaderTable(true);
    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  const DeleteRecord = EmployeeAppraisalRepository.useDeleteEmployeeGoalAdmin(
    Number(idEmployee),
    idEvaluation,
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const handleDelete = (idClick: number) => {
    DeleteRecord(idClick);
  };

  const handleApproveClick = EmployeeAppraisalRepository.usePutEmployeeAppraisalAccept(
    Number(idEmployee),
    (data) => {
      if (updateGoalUser) {
        updateGoalUser(data);
      }
    }
  );

  const handleUnapproveClick = EmployeeAppraisalRepository.usePutEmployeeAppraisalNotAccepted(
    Number(idEmployee),
    (data) => {
      if (updateGoalUser) {
        updateGoalUser(data);
      }
    }
  );

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="target60" />
                </div>
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfo">
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoLabel">
                    Cele roczne
                  </div>
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące celów pracownika
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="EmployeeAppraisalGoalBoxSecondRow">
            <>
              <div id="EmployeeAppraisalGoalBoxSecondRowFirstColumn">
                <>
                  <Button
                    id="EmployeeAppraisalGoalAddArea"
                    className="green "
                    onClick={() => {
                      setAddFormShow(true);
                    }}
                  >
                    Dodaj cel roczny
                  </Button>
                  {employeeAppraisalStatus?.settingId !== 7 ? (
                    <Button
                      id="EmployeeAppraisalGoalImportArea"
                      className="green"
                      onClick={() => {
                        setImportFormShow(true);
                      }}
                    >
                      Importuj cel roczny
                    </Button>
                  ) : (
                    ''
                  )}
                </>
              </div>
              <div id="EmployeeAppraisalGoalBoxSecondRowSecondColumn">
                <div id="countGoal">
                  <div id="countGoalLabel">Dodano celów:</div>
                  <div id="countGoalData">
                    {dataGoal?.content.length}
                    {employeeAppraisalStatus &&
                    employeeAppraisalStatus?.settingId < 7 ? (
                      <>
                        <span>/</span> 5
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content && dataGoal?.content.length > 0 ? (
                <>
                  <EmployeeAppraisalGoalList
                    dataGoal={dataGoal.content}
                    setEditFormShow={setEditFormShow}
                    setModalId={setModalId}
                    deleteAction={handleDelete}
                  />
                </>
              ) : (
                <div id="noGoalInAppraisal">Brak przypisanych celów</div>
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>

      {employeeAppraisalStatus?.interviewType !== 'LAST_ANNUAL' &&
      employeeAppraisalStatus?.interviewType !== 'NONE' ? (
        <ContentBox id="approveIDP">
          <>
            <Button className="green" onClick={() => setShowModalYesNo(true)}>
              {employeeAppraisalStatus?.interviewType === 'FIRST_ANNUAL'
                ? 'ZATWIERDŹ CELE ROCZNE'
                : 'ZATWIERDŹ ROZMOWĘ PÓŁROCZNĄ'}
            </Button>
            {showModalYesNo ? (
              <ModalYesNo
                changeModalShow={setShowModalYesNo}
                yesAction={() => handleApproveClick()}
                noAction={() => setShowModalYesNo(false)}
                title={
                  employeeAppraisalStatus?.interviewType === 'FIRST_ANNUAL'
                    ? 'ZATWIERDZENIE CELÓW ROCZNYCH'
                    : 'ZATWIERDZENIE ROZMOWY PÓŁROCZNEJ'
                }
                additionalText="Czy chcesz zatwierdzić cele roczne? Po ich zatwierdzeniu użytkownicy nie bedą można wprowadzać żadnych zmian do następnej rozmowy. Czy chcesz zatwierdzić je teraz?"
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {(settings?.interviewType === 'ANNUAL' ||
        settings?.interviewType === 'SIX_MONTH') &&
      employeeAppraisalStatus?.interviewType === 'NONE' &&
      employeeAppraisalStatus.settingId === settings.id ? (
        <ContentBox id="approveIDP">
          <>
            <Button
              className="red"
              onClick={() => setShowModalUnapproval(true)}
            >
              WYCOFAJ ZATWIERDZENIE
            </Button>
            {showModalUnapproval ? (
              <ModalYesNo
                changeModalShow={setShowModalUnapproval}
                yesAction={() => handleUnapproveClick()}
                noAction={() => setShowModalUnapproval(false)}
                title="ZATWIERDZENIE CELÓW ROCZNYCH"
                additionalText="Czy chcesz wycofać zatwierdzenie celów rocznych? Po ich wycofaniu użytkownicy znów będa mogli wprowadzać zmiany. Czy chcesz wycofać zatwierdzenie teraz?"
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {addFormShow ? (
        <EmployeeAppraisalGoalAdd
          changeModalAdd={setAddFormShow}
          changeUpdateData={setUpdateDataTable}
          changeModalEdit={setEditFormShow}
          changeModalId={setModalId}
          idEmployee={Number(idEmployee)}
          idEvaluation={idEvaluation}
          employeeAppraisalStatus={employeeAppraisalStatus}
        />
      ) : (
        ''
      )}
      {editFormShow ? (
        <EmployeeAppraisalGoalEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
          modalId={modalId}
          employeeAppraisalStatus={employeeAppraisalStatus}
          idEvaluation={idEvaluation}
        />
      ) : (
        ''
      )}
      {importFormShow ? (
        <EmployeeAppraisalGoalImport
          changeModalImport={setImportFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
          idEvaluation={idEvaluation}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
