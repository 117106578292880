import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  EmployeeAppraisalGoal,
  EmployeeAppraisalPostAndPutGoal,
} from 'api/types';
import { Modal } from 'components';
import FormIDPEmployeeArea from '../../../Form/EmployeeAppraisalGoalForm';

interface CompetencesProps {
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
  modalId: number;
  lastEdit?: boolean;
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
}
const EmployeeAppraisalGoalEdit = (props: CompetencesProps): ReactElement => {
  const {
    idEmployee,
    changeModalEdit,
    changeUpdateData,
    modalId,
    lastEdit,
    employeeAppraisalStatus,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    EmployeeGoal,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalEmployeeGoal({
    idEmployee: Number(idEmployee),
    idGoal: modalId,
  });

  const postDataSave = EmployeeAppraisalRepository.usePutEmployeeAppraisalEmployeeGoal(
    idEmployee,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const postDataStatusSave = EmployeeAppraisalRepository.usePutEmployeeAppraisalPostAndPutGoalStatus(
    idEmployee,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeArea = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    if (
      employeeAppraisalStatus?.interviewType === 'NONE' ||
      employeeAppraisalStatus?.interviewType === 'LAST_ANNUAL'
    ) {
      postDataStatusSave({
        progress: dataPost.progress,
        status: dataPost.status,
      });
    } else {
      postDataSave(dataPost);
    }
  };

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutEmployeeAppraisalEmployeeGoal(
    idEmployee,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );
  const postDataStatusSaveAndExit = EmployeeAppraisalRepository.usePutEmployeeAppraisalPostAndPutGoalStatus(
    idEmployee,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    if (
      employeeAppraisalStatus?.interviewType === 'NONE' ||
      employeeAppraisalStatus?.interviewType === 'LAST_ANNUAL'
    ) {
      postDataStatusSaveAndExit({
        progress: dataPost.progress,
        status: dataPost.status,
      });
    } else {
      postDataSaveAndExit(dataPost);
    }
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit} widthBox="high">
      <div>
        <FormIDPEmployeeArea
          onSave={handleAddIDPEmployeeArea}
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          edit
          data={EmployeeGoal}
          editOnlyStatus={
            employeeAppraisalStatus?.interviewType === 'NONE' ||
            employeeAppraisalStatus?.interviewType === 'LAST_ANNUAL'
          }
          settingsId={employeeAppraisalStatus?.settingId}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalEdit;
