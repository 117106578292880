import {
  EmployeeAppraisalRepository,
  IDPRepository,
  QuestionaireRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import { settings } from 'cluster';
import { DesktopItem } from 'components';
import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import TrainingsList from '../TrainingsList';

const UserItem = (): ReactElement => {
  const history = useHistory();

  /// TRAINING START
  const [statusTrainingPlan] = TrainingsGroupRepository.useGetCurrentPlanTest(
    {}
  );

  const checkStatusTraining = () => {
    if (statusTrainingPlan === undefined) {
      return 'loading';
    }
    if (statusTrainingPlan === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const startDate = new Date(statusTrainingPlan.startDate);
    startDate.setHours(0, 0);
    const endDate = new Date(statusTrainingPlan.endDate);
    endDate.setHours(23, 59);
    const registrationStartDate = new Date(
      statusTrainingPlan.registrationStartDate
    );
    registrationStartDate.setHours(0, 0);
    const registrationEndDate = new Date(
      statusTrainingPlan.registrationEndDate
    );
    endDate.setHours(23, 59);
    const verificationDate = new Date(statusTrainingPlan.verificationDate);
    verificationDate.setHours(0, 0);

    if (statusTrainingPlan !== null && (startDate > now || endDate < now)) {
      return 'noActivePlan';
    }
    if (statusTrainingPlan !== null && registrationStartDate > now) {
      return 'waitingForRegistration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationStartDate <= now &&
      registrationEndDate >= now
    ) {
      return 'registration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationEndDate < now &&
      verificationDate >= now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'verification';
    }
    if (
      statusTrainingPlan !== null &&
      verificationDate < now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'waitForTrainingGroup';
    }
    if (
      statusTrainingPlan !== null &&
      statusTrainingPlan.groupNotificationTime !== null
    ) {
      return 'trainingGroup';
    }
    return null;
  };

  const setButtonTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'registration') {
      return {
        text: 'Zapisz się na szkolenia',
        action: () => {
          history.push('/panel/szkolenia');
        },
      };
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return {
        text: 'Zapisz się do grupy',
        action: () => {
          history.push('/panel/szkolenia/termin-szkolenia');
        },
      };
    }
    return undefined;
  };
  const setContentTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie zapisów na szkolenia. O ich rozpoczęciu
            powiadomimy Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'registration') {
      return (
        <>
          <div>
            Zapisy na szkolenia są aktywne, zapisz się na szkolenia już teraz.
          </div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.registrationEndDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'verification') {
      return (
        <>
          <div>
            Trwa proces zatwierdzania szkoleń oraz przygotowywania grup
            szkoleniowych.
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return (
        <>
          <div>Oczekiwanie na utworznie grup szkoleniowych</div>
        </>
      );
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return (
        <>
          <div>Zapisz się na szkolenie w dogodnym dla Ciebie terminie</div>
          <div>Pamiętaj że decyduje kolejność zgłoszeń</div>
        </>
      );
    }

    return null;
  };

  /// TRAINING END

  /// IDP START
  const [statusIDP] = IDPRepository.useGetIDPSettings({});
  const [userIDP] = IDPRepository.useGetIDPEmployeeUser({});
  const checkStatusIDP = () => {
    if (statusIDP === undefined) {
      return 'loading';
    }
    if (statusIDP === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const annualInterviewStart = new Date(statusIDP.annualInterviewStart);
    annualInterviewStart.setHours(0, 0);
    const annualInterviewEnd = new Date(statusIDP.annualInterviewEnd);
    annualInterviewEnd.setHours(23, 59);
    const sixMonthInterviewStart = new Date(statusIDP.sixMonthInterviewStart);
    sixMonthInterviewStart.setHours(0, 0);
    const sixMonthInterviewEnd = new Date(statusIDP.sixMonthInterviewEnd);
    sixMonthInterviewEnd.setHours(23, 59);
    const nextAnnualInterviewStart = new Date(
      statusIDP.nextAnnualInterviewStart
    );
    nextAnnualInterviewStart.setHours(0, 0);
    const nextAnnualInterviewEnd = new Date(statusIDP.nextAnnualInterviewEnd);
    nextAnnualInterviewEnd.setHours(23, 59);

    if (statusIDP !== null && annualInterviewStart > now) {
      return 'waitingForRegistration';
    }
    if (
      statusIDP !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now &&
      (userIDP === null || userIDP?.interviewType === 'FIRST_ANNUAL')
    ) {
      return 'registration';
    }
    if (
      statusIDP !== null &&
      annualInterviewEnd < now &&
      sixMonthInterviewStart > now
    ) {
      return 'waitingForSixMonth';
    }
    if (
      statusIDP !== null &&
      sixMonthInterviewStart <= now &&
      sixMonthInterviewEnd >= now
    ) {
      return 'sixMonth';
    }
    if (
      statusIDP !== null &&
      sixMonthInterviewEnd < now &&
      now < nextAnnualInterviewStart
    ) {
      return 'waitingForNextAnnual';
    }
    if (
      statusIDP !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now &&
      userIDP?.interviewType === 'LAST_ANNUAL'
    ) {
      return 'NextAnnual';
    }

    return null;
  };

  const setButtonIDP = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusIDP() === 'registration') {
      return {
        text: 'Wpisz cele rozwojowe',
        action: () => {
          history.push('/panel/IDP/dane-podstawowe');
        },
      };
    }
    if (checkStatusIDP() === 'sixMonth') {
      return {
        text: 'Edytuj cele rozwojowe',
        action: () => {
          history.push('/panel/IDP/dane-podstawowe');
        },
      };
    }

    if (checkStatusIDP() === 'NextAnnual') {
      return {
        text: 'Edytuj cele rozwojowe',
        action: () => {
          history.push('/panel/IDP/dane-podstawowe');
        },
      };
    }
    return undefined;
  };
  const setContentIDP = () => {
    if (checkStatusIDP() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusIDP() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie planu IDP. O jego rozpoczęciu powiadomimy
            Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusIDP() === 'registration') {
      return (
        <>
          <div>
            Aktywny proces wprowadzania danych, wprowadź swoje cele rozwojowe
            już teraz.
          </div>
          <div>masz czas do</div>
          <div className="dataGreen">{statusIDP?.annualInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForSixMonth') {
      return (
        <>
          <div>Rozmowy półroczne zaczną się</div>
          <div className="dataGreen">{statusIDP?.sixMonthInterviewStart}</div>
          <div>W każdej chwili możesz podejrzeć swój plan IDP</div>
        </>
      );
    }
    if (checkStatusIDP() === 'sixMonth') {
      return (
        <>
          <div>
            Trwa proces rozmów półrocznych jego koniec planowany jest na
          </div>
          <div className="dataGreen">{statusIDP?.sixMonthInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForNextAnnual') {
      return (
        <>
          <div>Rozmowy roczne zaczną się</div>
          <div className="dataGreen">{statusIDP?.nextAnnualInterviewStart}</div>
          <div>W każdej chwili możesz podejrzeć swój plan IDP</div>
        </>
      );
    }
    if (checkStatusIDP() === 'NextAnnual') {
      return (
        <>
          <div>Trwa proces rozmów rocznych jego koniec planowany jest na</div>
          <div className="dataGreen">{statusIDP?.nextAnnualInterviewEnd}</div>
        </>
      );
    }

    return null;
  };

  /// IDP END
  /// Appraisal START
  const [
    statusAppraisal,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});
  const [userAppraisal] = EmployeeAppraisalRepository.useGetGoalUser();
  const checkStatusAppraisal = () => {
    console.log(statusAppraisal);
    console.log(userAppraisal);
    if (statusAppraisal === undefined) {
      return 'loading';
    }
    if (statusAppraisal === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const annualInterviewStart = new Date(statusAppraisal.annualInterviewStart);
    annualInterviewStart.setHours(0, 0);
    const annualInterviewEnd = new Date(statusAppraisal.annualInterviewEnd);
    annualInterviewEnd.setHours(23, 59);
    const sixMonthInterviewStart = new Date(
      statusAppraisal.sixMonthInterviewStart
    );
    sixMonthInterviewStart.setHours(0, 0);
    const sixMonthInterviewEnd = new Date(statusAppraisal.sixMonthInterviewEnd);
    sixMonthInterviewEnd.setHours(23, 59);
    const nextAnnualInterviewStart = new Date(
      statusAppraisal.nextAnnualInterviewStart
    );
    nextAnnualInterviewStart.setHours(0, 0);
    const nextAnnualInterviewEnd = new Date(
      statusAppraisal.nextAnnualInterviewEnd
    );
    nextAnnualInterviewEnd.setHours(23, 59);

    if (statusAppraisal !== null && annualInterviewStart > now) {
      return 'waitingForRegistration';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now &&
      (userAppraisal === null ||
        userAppraisal?.interviewType === 'FIRST_ANNUAL')
    ) {
      return 'registration';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now &&
      (userAppraisal === null || userAppraisal?.interviewType === 'NONE')
    ) {
      return 'waitingForSixMonth';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewEnd < now &&
      sixMonthInterviewStart > now
    ) {
      return 'waitingForSixMonth';
    }
    if (
      statusAppraisal !== null &&
      sixMonthInterviewStart <= now &&
      sixMonthInterviewEnd >= now
    ) {
      return 'sixMonth';
    }
    if (
      statusAppraisal !== null &&
      sixMonthInterviewEnd < now &&
      now < nextAnnualInterviewStart
    ) {
      return 'waitingForNextAnnual';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now &&
      userAppraisal?.interviewType === 'LAST_ANNUAL'
    ) {
      return 'NextAnnual';
    }

    return null;
  };

  const setButtonAppraisal = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusAppraisal() === 'registration') {
      return {
        text: 'Wpisz cele roczne',
        action: () => {
          history.push('/panel/ocena-pracownika/cele-roczne');
        },
      };
    }
    if (checkStatusAppraisal() === 'sixMonth') {
      return {
        text: 'Edytuj cele roczne',
        action: () => {
          history.push('/panel/ocena-pracownika/cele-roczne');
        },
      };
    }
    if (checkStatusAppraisal() === 'NextAnnual') {
      return {
        text: 'Edytuj cele roczne',
        action: () => {
          history.push('/panel/ocena-pracownika/cele-roczne');
        },
      };
    }
    return undefined;
  };
  const setContentAppraisal = () => {
    if (checkStatusAppraisal() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusAppraisal() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu szkoleń</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie planu Oceny Pracownika. O jego
            rozpoczęciu powiadomimy Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'registration') {
      return (
        <>
          <div>
            Aktywny proces wprowadzania danych, wprowadź swoje cele już teraz.
          </div>
          <div>masz czas do</div>
          <div className="dataGreen">{statusAppraisal?.annualInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForSixMonth') {
      return (
        <>
          <div>Rozmowy półroczne zaczną się</div>
          <div className="dataGreen">
            {statusAppraisal?.sixMonthInterviewStart}
          </div>
          <div>W każdej chwili możesz podejrzeć swój plan</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'sixMonth') {
      return (
        <>
          <div>
            Trwa proces rozmów półrocznych jego koniec planowany jest na
          </div>
          <div className="dataGreen">
            {statusAppraisal?.sixMonthInterviewEnd}
          </div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForNextAnnual') {
      return (
        <>
          <div>Rozmowy roczne zaczną się</div>
          <div className="dataGreen">
            {statusAppraisal?.nextAnnualInterviewStart}
          </div>
          <div>W każdej chwili możesz podejrzeć swój plan</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'NextAnnual') {
      return (
        <>
          <div>Trwa proces rozmów rocznych jego koniec planowany jest na</div>
          <div className="dataGreen">
            {statusAppraisal?.id === userAppraisal?.settingId
              ? statusAppraisal?.nextAnnualInterviewEnd
              : statusAppraisal?.annualInterviewEnd}
          </div>
        </>
      );
    }

    return null;
  };

  /// Appraisal END

  const [
    getQuestionaire,
    statusQuestionaire,
  ] = QuestionaireRepository.useGetAllQuestionnaire();

  useEffect(() => {
    getQuestionaire();
  }, [getQuestionaire]);

  const checkStatusQuestionaire = () => {
    if (statusQuestionaire === undefined) {
      return 'loading';
    }
    if (statusQuestionaire && statusQuestionaire?.content.length === 0) {
      return 'noQuestionaire';
    }
    if (statusQuestionaire && statusQuestionaire?.content.length > 0) {
      return 'questionaireToBeCompleted';
    }

    return null;
  };

  const setContentQuestionaire = () => {
    if (checkStatusQuestionaire() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusQuestionaire() === 'noQuestionaire') {
      return <div>Brak ankiet do wypełnienia</div>;
    }
    if (checkStatusQuestionaire() === 'questionaireToBeCompleted') {
      return (
        <>
          <div>Masz</div>
          <div className="dataGreen">
            <span>{statusQuestionaire?.content.length}</span>
            {statusQuestionaire && statusQuestionaire?.content.length === 1
              ? ' ankietę'
              : ''}
            {statusQuestionaire &&
            statusQuestionaire?.content.length > 1 &&
            statusQuestionaire?.content.length < 5
              ? ' ankiety'
              : ''}
            {statusQuestionaire && statusQuestionaire?.content.length > 4
              ? ' ankiet'
              : ''}
          </div>
          <div>do wypełnienia, zrób to teraz</div>
        </>
      );
    }

    return <div>Brak ankiet do wypełnienia</div>;
  };

  const setButtonQuestionaire = () => {
    if (checkStatusQuestionaire() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusQuestionaire() === 'questionaireToBeCompleted') {
      return {
        text: 'Wypełnij ankiety',
        action: () => {
          history.push('/panel/szkolenia/ankiety');
        },
      };
    }
    return null;
  };

  return (
    <div id="userDesktopBoxContent">
      <DesktopItem
        id="trainings"
        name="SZKOLENIA"
        buttonTitle={setButtonTraining()?.text}
        buttonAction={setButtonTraining()?.action}
      >
        <>{setContentTraining()}</>
      </DesktopItem>
      <DesktopItem
        id="idp"
        name="IDP"
        buttonTitle={setButtonIDP()?.text}
        buttonAction={setButtonIDP()?.action}
      >
        <>{setContentIDP()}</>
      </DesktopItem>
      <DesktopItem
        id="appraisal"
        name="CELE ROCZNE"
        buttonTitle={setButtonAppraisal()?.text}
        buttonAction={setButtonAppraisal()?.action}
      >
        <>{setContentAppraisal()}</>
      </DesktopItem>
      <DesktopItem
        id="questionnaire"
        name="ANKIETY"
        buttonTitle={setButtonQuestionaire()?.text}
        buttonAction={setButtonQuestionaire()?.action}
      >
        <>{setContentQuestionaire()}</>
      </DesktopItem>
      <TrainingsList id="trainingsList" name="LISTA SZKOLEŃ DO ODBYCIA" />
    </div>
  );
};

export default UserItem;
