import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  EmployeeAppraisalGoal,
  EmployeeAppraisalPostAndPutGoal,
} from 'api/types';
import { Modal } from 'components';
import EmployeeAppraisalGoalForm from '../../../Form/EmployeeAppraisalGoalForm';

interface Props {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
}
const EmployeeAppraisalGoalAdd = (props: Props): ReactElement => {
  const {
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateData,
    employeeAppraisalStatus,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = EmployeeAppraisalRepository.usePostGoalForUser(
    async (data) => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
      if (changeModalId) {
        changeModalId(data.id);
      }
      if (changeModalEdit) {
        changeModalEdit(true);
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeArea = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    postDataSave(dataPost);
  };

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePostGoalForUser(
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd} widthBox="high">
      <div>
        <EmployeeAppraisalGoalForm
          onSave={handleAddIDPEmployeeArea}
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          settingsId={employeeAppraisalStatus?.settingId}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalAdd;
